<template>
  <div class="container mx-auto pt-36 lg:pt-32 ">
    <div class="flex flex-wrap justify-center w-full bg-white">
      <div class="text-black w-12x4 md:w-full">
        <div class="flex flex-wrap justify-center">
          <div class="px-4">
            <img
                    src="img/collage.jpg"
                    alt="imagen decorativa"
                    class="h-auto align-middle border-none md:mt-10"
            />
          </div>
        </div>
      </div>
      <div class="text-black w-12x4 md:w-2/3">
        <article class="p-5 m-1 mt-10 mb-12 text-left text-center bg-gray-100 rounded-2xl">
          <p class="mt-8 text-2xl">Ocupate de tus obligaciones</p>
          <p>y solicita un presupuesto sin compromiso, llenando el siguiente <router-link to="/presupuesto" class="text-blue-700 underline font-bold" title="ir al pedido de presupuesto">formulario</router-link> que nosotros nos encargamos de todo lo siguiente: </p>
        </article>

          <ul>
            <li class="item-servicios">
              <p class="parrafo-pagina-centrado-servicios"><font-awesome-icon icon="fa-chevron-right"></font-awesome-icon>&ensp;Asesorarte en la elección del terreno donde implantar tu futura vivienda, industria, oficina</p>
              <p class="text-left">Te guiaremos en la elección del mejor lote de acuerdo a tus posibilidades y necesidades</p>
            </li>
            <li class="item-servicios">

              <p class="parrafo-pagina-centrado-servicios "><font-awesome-icon icon="fa-chevron-right"></font-awesome-icon>&ensp;Diseñar tu casa, tu industria u oficina a medida con vision de crecimiento</p>
              <p class="text-left">Te ayudamos a formular tu programa de necesidades haciendote un diseño personalizado según tu gusto y estilo.</p>
            </li>
            <li class="item-servicios">
              <p class="parrafo-pagina-centrado-servicios"><font-awesome-icon icon="fa-chevron-right"></font-awesome-icon>&ensp;Gestionar y tramitar el plano para solicitar el permiso ante el municipio</p>
              <p class="text-left">Realizamos todos los trámites ante municipio hasta la aprobación de los planos autorizando la construccion.</p>
            </li>
            <li class="item-servicios">
              <p class="parrafo-pagina-centrado-servicios"><font-awesome-icon icon="fa-chevron-right"></font-awesome-icon>&ensp;Coordinar la elaboracion del proyecto y la obra</p>
              <p class="text-left">Coordinamos todas las etapas desde el diseño, trámites, compra de materiales y construcción.</p>
            </li>
            <li class="item-servicios">
              <p class="parrafo-pagina-centrado-servicios"><font-awesome-icon icon="fa-chevron-right"></font-awesome-icon>&ensp;Estudiar y documentar el proyecto para sus ejecucion</p>
              <p class="text-left">Esta dentro de nuestros objetivos primarios la reduccion al minimo de situaciones imprevitas</p>
            </li>
            <li class="item-servicios">
              <p class="parrafo-pagina-centrado-servicios"><font-awesome-icon icon="fa-chevron-right"></font-awesome-icon>&ensp;Realizar el cálculo estructural y de todas las ingenierias del proyecto</p>
              <p class="text-left">Nuestro Departamento de Ingeniería estudia, proyecta y dimensiona todas las instalaciones como su estructura soporte</p>
            </li>
            <li class="item-servicios">
              <p class="parrafo-pagina-centrado-servicios"><font-awesome-icon icon="fa-chevron-right"></font-awesome-icon>&ensp;Planificar las tareas de obra</p>
              <p class="text-left">Planificamos y coordinamos el personal de obra en todas las etapas tratando de evitar desvios de tiempos de ejecucion</p>
            </li>
            <li class="item-servicios">
              <p class="parrafo-pagina-centrado-servicios"><font-awesome-icon icon="fa-chevron-right"></font-awesome-icon>&ensp;Comprar y entregar los materiales</p>
              <p class="text-left">Realizamos la logística de compra y entrega en obra de todos los materiales.</p>
            </li>
            <li class="item-servicios">
              <p class="parrafo-pagina-centrado-servicios"><font-awesome-icon icon="fa-chevron-right"></font-awesome-icon>&ensp;Dirigir la obra</p>
              <p class="text-left">Contamos con profesionales especificos encargados de la dirección y control de calidad de la obra.</p>
            </li>
            <li class="item-servicios">
              <p class="parrafo-pagina-centrado-servicios"><font-awesome-icon icon="fa-chevron-right"></font-awesome-icon>&ensp;Asesorarte en la elección de terminaciones</p>
              <p class="text-left">Te guiaremos en el diseño y en la elección de los mejores productos.</p>
            </li>
            <li class="item-servicios">
              <p class="parrafo-pagina-centrado-servicios"><font-awesome-icon icon="fa-chevron-right"></font-awesome-icon>&ensp;Comunicar anticipadamente algun desvio economico</p>
              <p class="text-left">Contamos con un sistema de control de costos y financiero que nos permite anticiparnos a un posible desvio economico y tomar las acciones correctivas necesarias.</p>
            </li>
            <li class="item-servicios">
              <p class="parrafo-pagina-centrado-servicios"><font-awesome-icon icon="fa-chevron-right"></font-awesome-icon>&ensp;Estudiar la factibilidad y viabilidad economica en caso de un emprendimiento inmobiliario</p>
              <p class="text-left">A traves de estudios de sensibilidad, escenarios de maxima y de minima y de punto muerto.</p>
            </li>
          </ul>
          <p class="mt-20 text-2xl">Te brindamos una solución a la construcción de tu casa por el sistema llave en mano o por cuenta y orden</p>
          <p class="mt-8 text-2xl">¡solicitá el presupuesto llenando el <router-link to="/presupuesto" class="text-blue-700 underline font-bold" title="ir al pedido de presupuesto">formulario</router-link> o comunicate directamente!</p>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home"
};
</script>
